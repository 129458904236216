import ladogaImg from "./../media/img/teplohod-img/Ladoga/1.jpg"
import vizantImg from "./../media/img/teplohod-img/Vizantiya/1.jpg"
import hollanderImg from "./../media/img/teplohod-img/Hollander/1.jpg"
import izhoraImg from "./../media/img/teplohod-img/Izhora/06-14-54_20200713_2B3A0347.jpg"
import argoImg from "./../media/img/teplohod-img/Argo/photo_2024-04-24_22-34-28.jpg"

const teplohodInfo = [
    {
        id: '0',
        title: 'Ладога',
        price: 'от 7 000,00 ₽/час',
        img: ladogaImg,
        length: '',
        passengers: '11',
        port: 'Набережная реки Мойки д.12',
    },
    {
        id: '1',
        title: 'Византия',
        price: 'от 10 000,00 ₽/час',
        img: vizantImg,
        length: '12 м',
        passengers: '10',
        port: 'Набережная реки Мойки д.12',
    },
    {
        id: '2',
        title: 'Голландец',
        price: 'от 5 000,00 ₽/час',
        img: hollanderImg,
        length: '',
        passengers: '11',
        port: 'Набережная канала Грибоедова д.61',
    },
    {
        id: '3',
        title: 'Ижора',
        price: 'от 5 500,00 ₽/час',
        img: izhoraImg,
        length: '',
        passengers: '11',
        port: 'Набережная реки Фонтанки д.105',
    },
    {
        id: '4',
        title: 'Арго',
        price: 'от 8 000,00 ₽/час',
        img: argoImg,
        length: '',
        passengers: '11',
        port: '',
    },

];

export { teplohodInfo }