function importAllTeplohod(r) {
    return r.keys().map(r);
}

export function getSlideContextTeplohod(teplohodInfo) {
    let slideContextTeplohod;

    if (teplohodInfo && teplohodInfo.id === '0') {
        slideContextTeplohod = importAllTeplohod(require.context("./../media/img/teplohod-img/Hollander", false, /\.(jpg)$/));
    } else if (teplohodInfo && teplohodInfo.id === '1') {
        slideContextTeplohod = importAllTeplohod(require.context("./../media/img/teplohod-img/Ladoga", false, /\.(jpg)$/));
    } else if (teplohodInfo && teplohodInfo.id === '2') {
        slideContextTeplohod = importAllTeplohod(require.context("./../media/img/teplohod-img/Vizantiya", false, /\.(jpg)$/));
    } else if (teplohodInfo && teplohodInfo.id === '3') {
        slideContextTeplohod = importAllTeplohod(require.context("./../media/img/teplohod-img/Izhora", false, /\.(jpg)$/));
    } else if (teplohodInfo && teplohodInfo.id === '4') {
        slideContextTeplohod = importAllTeplohod(require.context("./../media/img/teplohod-img/Argo", false, /\.(jpg)$/));
    } else {
        slideContextTeplohod = [];
    }
    
    return slideContextTeplohod;
    }