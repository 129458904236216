import "./style.css";
import { useEffect } from "react";

function Burger() {

    useEffect(() => {
        const handleClick = () => {
            const burger = document.querySelector('.burger');
            const menu = document.querySelector('.nav-right');
            if (burger && menu) {
                burger.classList.toggle('active');
                menu.classList.toggle('open');

                if (menu.classList.contains('open')) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            }
        };

        const handleLinkClick = () => {
            const burger = document.querySelector('.burger');
            const menu = document.querySelector('.nav-right');
            if (burger && menu) {
                burger.classList.remove('active');
                menu.classList.remove('open');
                document.body.style.overflow = 'auto';
            }
        };

        const burgerBtn = document.querySelector('.burger');
        if (burgerBtn) {
            burgerBtn.addEventListener('click', handleClick);
        }

        const navLinks = document.querySelectorAll('.nav-right a');
        navLinks.forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        return () => {
            if (burgerBtn) {
                burgerBtn.removeEventListener('click', handleClick);
            }
            navLinks.forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    return (
        <div className="burger">
            <span></span>
        </div>
    )
}

export default Burger;