import "./fairline65.css"
import { useParams } from "react-router-dom"
import { katerInfo } from "../../helpers/katerInfo";
import { SwiperKater } from "./../../components/swiper/SwiperKater"

const Katera65 = () => {
    const { id } = useParams();
    const kateraInfo = katerInfo[id];

    return (<><SwiperKater katerInfo={kateraInfo} />
        <div className="featured">
            <p>
                {kateraInfo.title}
            </p>
        </div>
        <div className="char">
            <p>Длина</p>
            <p>{kateraInfo.length}</p>
        </div>
        <div className="char">
            <p>Количество пассажиров</p>
            <p>{kateraInfo.passengers}</p>
        </div>
        <div className="char">
            <p>Цена</p>
            <p>{kateraInfo.price}</p>
        </div>
        <div className="specs">
        <table>
            <tbody>
                <tr className="tr-g">
                    <td className="td">Общая длина</td>
                    <td className="td"><b>{kateraInfo.length}</b></td>
                </tr>
                <tr className="tr-w">
                    <td className="td">Максимальное количество пассажиров</td>
                    <td className="td"><b>{kateraInfo.passengers}</b></td>
                </tr>
                <tr className="tr-g">
                    <td className="td">Причал</td>
                    <td className="td"><b>{kateraInfo.port}</b></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div className="line"></div>
    </>);
}

export default Katera65;