import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ShowKaters from "../components/show-yachts/ShowKaters";
import { SwiperMain } from "./../components/swiper/SwiperMain";
import { katerInfo } from "../helpers/katerInfo";
import "./style.css";
import "./yachts.css";

const Katers = () => {
    const itemsPerPage = 16;
    const [currentPage, setCurrentPage] = useState(1);
    const pageRef = useRef(null);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;

    const currentKaters = katerInfo.slice(startIndex, endIndex);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        if (pageRef.current) {
            setTimeout(() => {
                pageRef.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    }, [currentPage]);

    const paginationItems = [];
    const totalPages = Math.ceil(katerInfo.length / itemsPerPage);
    for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(
            <NavLink to="#" key={i} onClick={() => handlePageClick(i)}>{i}</NavLink>
        );
    }

    return (
        <><SwiperMain />
            <div className="featured">
                <p>Каталог катеров</p>
            </div>
            <div className="page-yachts" ref={pageRef}>
                {currentKaters.map((kater) => (
                    <ShowKaters
                        title={kater.title}
                        img={kater.img}
                        price={kater.price}
                        key={kater.id}
                        id={kater.id}
                    />
                ))}
            </div>
            <div className="pagination">
                {paginationItems}
            </div>
            <div className="line"></div>
        </>
    );
};

export default Katers;