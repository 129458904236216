function importAll(r) {
    return r.keys().map(r);
}


export function getSlideContext(yachtsInfo) {
let slideContext;

if (yachtsInfo && yachtsInfo.id === '0') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Atlantis", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '1') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Fairline65/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '2') {
    slideContext = importAll(require.context("./../media/img/yacht-img/miracle/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '3') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Elegance65/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '4') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Benetti92/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '5') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Dominator68/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '6') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Princess58/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '7') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Azimut55/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '8') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Amsterdam/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '9') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Monaco/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '10') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Princess50/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '11') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Galeon440/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '12') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Roadman44Holiday/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '13') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Majesty44/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '14') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Meridian381/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '15') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Broward98/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '16') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Memphis/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '17') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Galeon640/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '18') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Jocore/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '19') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Princess57/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '20') {
    slideContext = importAll(require.context("./../media/img/yacht-img/SeaLineF43MonteCarlo/", false, /\.(jpg)$/));
} else if (yachtsInfo && yachtsInfo.id === '21') {
    slideContext = importAll(require.context("./../media/img/yacht-img/Azimut62S/", false, /\.(jpg)$/));
} else {
    slideContext = [];
}

return slideContext;
}
