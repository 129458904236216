import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import './style.css';
import { getSlideContextKater } from './../../utils/importKaters';


export function SwiperKater({ katerInfo }) {
    const slideContextKater = getSlideContextKater(katerInfo);

    return (
        <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            lazyPreloadPrevNext={0}
            navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }}
            autoplay={{
                delay: 6000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
            }}
            speed={800}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {slideContextKater.map((slide, index) => (
                <SwiperSlide key={index}>
                    <img className="swiperyachts__img" src={slide} alt={`slide-${index}`} loading='lazy'/>
                </SwiperSlide>
            ))}
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
        </Swiper>
    );
}