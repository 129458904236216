import './style.css';
import CateringAdv from "./../components/catering-adv/CateringAdv"
import { SwiperCatering } from "./../components/swiper/SwiperCatering"

const Catering = () => {
    return (<><SwiperCatering />
        <div className="featured">
            Услуги кейтеринга
        </div>
        <CateringAdv />

        </>
     );
}
 
export default Catering;