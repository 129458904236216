import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import "./styles/style.css";


import Header from "./components/header/Header";
import { HomeSwiper } from "./pages/Home-swiper";
import FooterOne from "./components/footer-one/FooterOne";
import FooterTwo from "./components/footer-two/FooterTwo";

import Yachts from "./pages/Yachts";
import Katers from "./pages/Katers";
import Teplohods from "./pages/Teplohods";
import Catering from "./pages/Catering";

import Fairline65 from "./pages/yacht-pages/Fairline65";
import Katera65 from "./pages/yacht-pages/Katera65";
import Teplohod65 from "./pages/yacht-pages/Teplohod65";






function App() {
  return (
    <div className="App">
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<HomeSwiper />} />
            <Route path="yachts" element={<Yachts />} />
            <Route path="katers" element={<Katers />} />
            <Route path="teplohods" element={<Teplohods />} />
            <Route path="catering" element={<Catering />} />
            <Route path="cardyacht/:id" element={<Fairline65 />} />
            <Route path="cardkater/:id" element={<Katera65 />} />
            <Route path="cardteplohod/:id" element={<Teplohod65 />} />
          </Routes>
          <FooterOne />
          <FooterTwo />
        </Router>
    </div>
  );
}

export default App;
