import "./fairline65.css"
import {useParams} from "react-router-dom"
import { yachtsInfo } from "../../helpers/yachtsInfo";
import { SwiperYachts } from "./../../components/swiper/SwiperYachts"

const Fairline65 = () => {
    const {id} = useParams();
    const yachtInfo = yachtsInfo[id];

    return (<><SwiperYachts yachtsInfo={yachtInfo} />
    <div className="featured">
        <p>
            {yachtInfo.title}
        </p>
    </div>
    <div className="char">
        <p>Длина</p>
        <p>{yachtInfo.length}</p>
    </div>
    <div className="char">
        <p>Количество пассажиров</p>
        <p>{yachtInfo.passengers}</p>
    </div>
    <div className="char">
        <p>Цена</p>
        <p>{yachtInfo.price}</p>
    </div>
    <div className="specs">
        <table>
            <tbody>
                <tr className="tr-g">
                    <td className="td">Общая длина</td>
                    <td className="td"><b>{yachtInfo.length}</b></td>
                </tr>
                <tr className="tr-w">
                    <td className="td">Ширина</td>
                    <td className="td"><b>{yachtInfo.width}</b></td>
                </tr>
                <tr className="tr-g">
                    <td className="td">Максимальное количество пассажиров</td>
                    <td className="td"><b>{yachtInfo.passengers}</b></td>
                </tr>
                <tr className="tr-w">
                    <td className="td">Причал</td>
                    <td className="td"><b>{yachtInfo.port}</b></td>
                </tr>
            </tbody>
        </table>
    </div>
    {yachtInfo.youtube && (
    <div className="tube"><iframe src={yachtInfo.youtube} frameBorder="0" allowFullScreen title="fairline65"></iframe></div>)}
    


    <div className="line"></div></>  );
}
 
export default Fairline65;