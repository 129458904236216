import "./style.css"
import { NavLink } from "react-router-dom";

const ShowOtherKater = ({ title, price, id, img }) => {
    return (

        <div className="catalog">
            <NavLink to={`/cardkater/${id}`} target="_blank">
                <div className="catalogpicture"><img src={img} alt={title} /></div>
            </NavLink>
            <div className="catalogyachtname">
                {title}
            </div>
            <div className="catalogyachtprice">
                <b>{price}</b>
            </div>
        </div>);
}

export default ShowOtherKater;