import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import './style.css';
import slide2 from "./../../media/img/catering-img/1.jpg";
import slide3 from "./../../media/img/catering-img/2.jpg";
import slide4 from "./../../media/img/catering-img/3.jpg";
import slide5 from "./../../media/img/catering-img/4.jpg";
import slide6 from "./../../media/img/catering-img/5.jpg";
import slide7 from "./../../media/img/catering-img/6.jpg";

export function SwiperCatering () {
    return (
        <Swiper
            modules={[Navigation, Autoplay,]}
            spaceBetween={50}
            slidesPerView={1}
            lazyPreloadPrevNext={0}
            navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }}
            autoplay={{
                delay: 6000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
            }}
            speed={800}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            <SwiperSlide><img src={slide2} alt="" loading='lazy'/></SwiperSlide>
            <SwiperSlide><img src={slide3} alt="" loading='lazy'/></SwiperSlide>
            <SwiperSlide><img src={slide4} alt="" loading='lazy'/></SwiperSlide>
            <SwiperSlide><img src={slide5} alt="" loading='lazy'/></SwiperSlide>
            <SwiperSlide><img src={slide6} alt="" loading='lazy'/></SwiperSlide>
            <SwiperSlide><img src={slide7} alt="" loading='lazy'/></SwiperSlide>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>

        </Swiper>
    );
};