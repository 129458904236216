import atlantisImage from "./../media/img/yacht-img/Atlantis/1.jpg"
import miracleImage from "./../media/img/yacht-img/miracle/1.jpg"
import eleganceImage from "./../media/img/yacht-img/Elegance65/2B3A4359.jpg"
import dominatorImage from "./../media/img/yacht-img/Dominator68/_DSC8885.jpg"
import princessImage from "./../media/img/yacht-img/Princess58/1.jpg"
import azimutImage from "./../media/img/yacht-img/Azimut55/1.jpg"
import amsterdamImage from "./../media/img/yacht-img/Amsterdam/1.jpg"
import monacoImage from "./../media/img/yacht-img/Monaco/2B3A6792.jpg"
import princesssImage from "./../media/img/yacht-img/Princess50/2023-07-27 17.06.48.jpg"
import roadmanImage from "./../media/img/yacht-img/Roadman44Holiday/1.jpg"
import memphisImage from "./../media/img/yacht-img/Memphis/1.jpg"
import fairlineImage from "./../media/img/yacht-img/Fairline65/DJI_0025.jpg"
import benettiImage from "./../media/img/yacht-img/Benetti92/1.jpg"
import majestyImage from "./../media/img/yacht-img/Majesty44/1.jpg"
import meridianImage from "./../media/img/yacht-img/Meridian381/4.jpg"
import galeon4Image from "./../media/img/yacht-img/Galeon440/1.jpg"
import galeon6Image from "./../media/img/yacht-img/Galeon640/1.jpg"
import jocoreImage from "./../media/img/yacht-img/Jocore/1.jpg"
import princess57Image from "./../media/img/yacht-img/Princess57/2B3A3558.jpg"
import f43Image from "./../media/img/yacht-img/SeaLineF43MonteCarlo/1.jpg"
import azimut62sImage from "./../media/img/yacht-img/Azimut62S/2B3A5585.jpg"
import broward98Image from "./../media/img/yacht-img/Broward98/607c30df-5372-4677-8eac-da2cef3a7168.jpg"


const yachtsInfo = [
    {
        id: '0',
        title: 'Atlantis',
        price: 'от 90 000,00 ₽/час',
        img:atlantisImage,
        length: '23,3 м',
        width: '6,1 м',
        passengers:'10',
        port: '',
        youtube:'',
    },
    {
        id: '1',
        title: 'Fairline 65',
        price: 'от 58 000,00 ₽/час',
        img:fairlineImage,
        length: '19.96 м',
        width: '5.23 м',
        passengers:'16',
        port: 'Южная дорога д.4 к.1',
        youtube:'',
    },
    {
        id: '2',
        title: 'Чудо лодка VIP',
        price: 'от 40 000,00 ₽/час',
        img:miracleImage,
        length: '12,9 м',
        width: '3,85 м',
        passengers:'10',
        port: '',
        youtube:'',
    },
    {
        id: '3',
        title: 'Elegance 65',
        price: 'от 40 000,00 ₽/час',
        img:eleganceImage,
        length: '19,5 м',
        width: '5,5 м',
        passengers: '16',
        rent: '',
        port: 'Петровская коса д.9',
        youtube:'',

    },
    {
        id: '4',
        title: 'Benetti 92',
        price: 'от 40 000,00 ₽/час',
        img:benettiImage,
        length: '27,75 м',
        width: '6,13 м',
        passengers: '10',
        port: '',
        youtube:'',

    },
    {
        id: '5',
        title: 'Dominator 68',
        price: 'от 35 000,00 ₽/час',
        img:dominatorImage,
        length: '21,32 м',
        width: '5,5 м',
        passengers: '16',
        port: '',
        youtube:'',
    },
    {
        id: '6',
        title: 'Princess 58',
        price: 'от 30 000,00 ₽/час',
        img:princessImage,
        length: '17,83 м',
        width: '4,83 м',
        passengers: '12',
        port: 'Южная дорога д.4',
        youtube:'',
    },
    {
        id: '7',
        title: 'Azimut 55',
        price: 'от 30 000,00 ₽/час',
        img:azimutImage,
        length: '16,9 м',
        width: '4,95 м',
        passengers: '12',
        port: 'Южная дорога д.4а',
        youtube:'',
    },
    {
        id: '8',
        title: 'Амстердам',
        price: 'от 30 000,00 ₽/час',
        img:amsterdamImage,
        length: '14,8 м',
        width: '4,9 м',
        passengers:'11',
        port: 'Набережная реки Мойки д.56',
        youtube:'',
    },
    {
        id: '9',
        title: 'Монако',
        price: 'от 30 000,00 ₽/час',
        img:monacoImage,
        length: '14,8 м',
        width: '4,2 м',
        passengers:'11',
        port: 'Набережная реки Мойки д.56',
        youtube:'',
    },
    {
        id: '10',
        title: 'Princess 50',
        price: 'от 25 000,00 ₽/час',
        img:princesssImage,
        length: '16,02 м',
        width: '4,08 м',
        passengers: '10',
        port: 'Южная дорога д.4',
        youtube:'',
    },
    {
        id: '11',
        title: 'Galeon 440',
        price: 'от 25 000,00 ₽/час',
        img:galeon4Image,
        length: '13,97 м',
        width: '4,2 м',
        passengers: '12',
        port: '',
        youtube:'',
    },
    {
        id: '12',
        title: 'Roadman 44 Holiday',
        price: 'от 23 000,00 ₽/час',
        img:roadmanImage,
        length: '13,6 м',
        width: '4,2 м',
        passengers: '10',
        port: 'Лопухинский сад, ул. Академика Павлова д.11а',
        youtube:'',
    },
    {
        id: '13',
        title: 'Majesty 44',
        price: 'от 18 000,00 ₽/час',
        img:majestyImage,
        length: '13,61 м',
        width: '4,34 м',
        passengers: '10',
        port: 'ул. Уральская д.13 лит.У',
        youtube:'',
    },
    {
        id: '14',
        title: 'Meridian 381',
        price: 'от 16 000,00 ₽/час',
        img:meridianImage,
        length: '11,73 м',
        width: '4,14 м',
        passengers: '10',
        port: 'Яхт-Клуб "Бриз", ул. Уральская д.13 лит.У',
        youtube:'',
    },
    {
        id: '15',
        title: 'Broward 98',
        price: 'от 50 000,00 ₽/час',
        img:broward98Image,
        length: '30 м',
        width: '6,4 м',
        passengers: '50',
        port: '',
        youtube:'',
    },
    {
        id: '16',
        title: 'Memphis',
        price: 'от 12 000,00 ₽/час',
        img:memphisImage,
        length: '10 м',
        width: '3,2 м',
        passengers: '10',
        port: 'Яхт-Клуб "Нева", набережная Мартынова д.96',
        youtube:'',
    },
    {
        id: '17',
        title: 'Galeon 640',
        price: 'от 50 000,00 ₽/час',
        img:galeon6Image,
        length: '18,42 м',
        width: '4,99 м',
        passengers: '13',
        port: '',
        youtube:'',
    },
    {
        id: '18',
        title: 'Jocore',
        price: 'от 25 000,00 ₽/час',
        img:jocoreImage,
        length: '12 м',
        width: '6,5 м',
        passengers: '11',
        port: 'Южная дорога д.4 к.1',
        youtube:'',
    },
    {
        id: '19',
        title: 'Princess 57 Esperanza',
        price: 'от 35 000,00 ₽/час',
        img:princess57Image,
        length: '17,47 м',
        width: '4,83 м',
        passengers: '10',
        port: 'Южная дорога д.4',
        youtube:'',
    },
    {
        id: '20',
        title: 'SeaLine F43 Monte Carlo',
        price: 'от 25 000,00 ₽/час',
        img:f43Image,
        length: '13,55 м',
        width: '4,2 м',
        passengers: '12',
        port: 'Императосркий яхт-клуб, наб. Мартынова д.92',
        youtube:'',
    },
    {
        id: '21',
        title: 'Azimut 62S',
        price: 'от 40 000,00 ₽/час',
        img:azimut62sImage,
        length: '19,26 м',
        width: '4,9 м',
        passengers: '12',
        port: 'Южная дорога д.4 к.1',
        youtube:'',
    },


];

export { yachtsInfo };