import chap25Img from "./../media/img/kater-img/Chaparral25/2B3A3567.jpg"
import katerinaImg from "./../media/img/kater-img/Katerina/2.jpg"
import katalinaImg from "./../media/img/kater-img/Katalina/1970-kater-katalina-1-1220x916.jpg"
import cieraImg from "./../media/img/kater-img/CieraMystic/3.jpg"
import silverImg from "./../media/img/kater-img/Silver/B58A6574.jpg"
import montereyImg from "./../media/img/kater-img/Monterey/2.jpg"
import californiaImg from "./../media/img/kater-img/California/1.jpg"
import doralImg from "./../media/img/kater-img/Doral300Barbados/3.jpg"
import pioneerImg from "./../media/img/kater-img/Pioneer/4.jpg"
import venetoImg from "./../media/img/kater-img/Veneto/IMG_9079.jpg"
import venуziaImg from "./../media/img/kater-img/Venezia/3.jpg"
import venуzianordImg from "./../media/img/kater-img/VeneziaNord/2B3A5489.jpg"
import prestigeImg from "./../media/img/kater-img/Prestige/1.jpg"
import katarinaImg from "./../media/img/kater-img/Katarina/227-Kater_Katarina_1-1220x916.jpg"
import venissaImg from "./../media/img/kater-img/Venissa/1.jpg"
import markImg from "./../media/img/kater-img/Mark/4.jpg"
import premieraImg from "./../media/img/kater-img/Premiera/2115-limusine-primiera-1-1220x916.jpg"
import velvetteImg from "./../media/img/kater-img/Velvette330Beluga/2B3A3574.jpg"
import efirImg from "./../media/img/kater-img/Efir330/2.jpg"
import yaltaImg from "./../media/img/kater-img/Yalta/2B3A5801.jpg"
import belugaImg from "./../media/img/kater-img/Beluga/2B3A0011.jpg"
import jerryImg from "./../media/img/kater-img/Jerry/IMG_1188.jpg"
import francescoImg from "./../media/img/kater-img/Francesco/IMG_3723.jpg"
import elchapaImg from "./../media/img/kater-img/ElChapa/_MG_2148.jpg"
import magnumImg from "./../media/img/kater-img/Magnum/IMG_7362.jpg"
import anastasiyaImg from "./../media/img/kater-img/Anastasiya/2.jpg"
import maryImg from "./../media/img/kater-img/Mary/2B3A2132.jpg"
import juliImg from "./../media/img/kater-img/Juliya/2B3A1190.jpg"
import storm2Img from "./../media/img/kater-img/Storm2/3.png"
import regal26Img from "./../media/img/kater-img/Regal26/2B3A4176.jpg"
import kiraImg from "./../media/img/kater-img/Kira/2B3A0638.jpg"
import baliImg from "./../media/img/kater-img/Bali/IMG_3113.jpg"
import chapssiImg from "./../media/img/kater-img/ChaparralSSI/IMG_8472.jpg"
import chapsigImg from "./../media/img/kater-img/ChaparralSignature/IMG_7894.jpg"
import crowline262Img from "./../media/img/kater-img/Crowline262/IMG_7477.jpg"
import fourwinsImg from "./../media/img/kater-img/Fourwinns/2.jpg"
import butterflyImg from "./../media/img/kater-img/Butterfly/2B3A6266.jpg"
import montecarloImg from "./../media/img/kater-img/MonteCarlo/7.jpg"
import doral300expImg from "./../media/img/kater-img/Doral330Expression/4.jpg"
import bort1Img from "./../media/img/kater-img/Bort1/photo_2024-04-19_04-17-51.jpg"
import shykinsinImg from "./../media/img/kater-img/ShykinSin/photo_2024-04-19_04-18-56.jpg"
import simbaImg from "./../media/img/kater-img/Simba/photo_2024-04-19_03-54-21.jpg"
import boliwarImg from "./../media/img/kater-img/Boliwar/2B3A8209.jpg"
import nordImg from "./../media/img/kater-img/Nord/photo_2024-04-19_04-05-42.jpg"
import interpolImg from "./../media/img/kater-img/Interpol/photo_2024-04-19_03-36-54.jpg"
import storm3Img from "./../media/img/kater-img/Storm3/photo_2024-04-19_03-53-18.jpg"
import siriusImg from "./../media/img/kater-img/Sirius/photo_2024-04-19_03-51-30.jpg"
import corsaImg from "./../media/img/kater-img/Corsa/2B3A5968.jpg"
import deaImg from "./../media/img/kater-img/Dea/DSC02353.jpg"
import wizardImg from "./../media/img/kater-img/Wizard/photo_2022-08-03_19-58-31.jpg"
import SeaRay270SDGrayImg from "./../media/img/kater-img/SeaRay270SDGray/IMG_2350.jpg"
import SeaRay270SLXBlackImg from "./../media/img/kater-img/SeaRay270SLXBlack/IMG_2497.jpg"
import brpImg from "./../media/img/kater-img/BRPChallenger/2.jpg"
import montferrandImg from "./../media/img/kater-img/Montferrand/2B3A3033.jpg"
import nordik38Img from "./../media/img/kater-img/Nordik38/2B3A0412.jpg"
import greenImg from "./../media/img/kater-img/Green/2B3A37793.jpg"
import garibaldiImg from "./../media/img/kater-img/Garibaldi/2B3A6226.jpg"
import chap204Img from "./../media/img/kater-img/Chaparral204/1.jpg"
import infinityImg from "./../media/img/kater-img/Infinity/PHOTO-2024-04-22-17-53-07.jpg"
import nashvilleImg from "./../media/img/kater-img/Nashville/PHOTO-2024-04-22-17-53-11.jpg"
import paradiseImg from "./../media/img/kater-img/Paradise/PHOTO-2024-04-22-17-53-15_2.jpg"
import lakkiImg from "./../media/img/kater-img/Lakki/PHOTO-2024-04-22-17-53-19.jpg"
import teodorImg from "./../media/img/kater-img/Teodor/IMG_8597.jpg"

const katerInfo = [
    {
        id: '0',
        title: 'Montferrand',
        price: 'от 25 000,00 ₽/час',
        img: montferrandImg,
        length: '11 м',
        port: 'наб. реки Мойки д3.',
        passengers: '10',
    },
    {
        id: '1',
        title: 'Chaparral 25',
        price: 'от 8 000,00 ₽/час',
        img: chap25Img,
        length: '8 м',
        port: 'ул. Уральская д. 13ц',
        passengers: '10',
    },
    {
        id: '2',
        title: 'Нордик 38',
        price: 'от 18 000,00 ₽/час',
        img: nordik38Img,
        length: '',
        port: 'Яхтенный порт, пр. Крузенштерна д.18 стр. 7',
        passengers: '11',
    },
    {
        id: '3',
        title: 'Katerina',
        price: 'от 10 000,00 ₽/час',
        img: katerinaImg,
        length: '8.5 м',
        port: 'Набережная реки Фонтанки д.53а',
        passengers: '7',
    },
    {
        id: '4',
        title: 'Katalina',
        price: 'от 9 000,00 ₽/час',
        img: katalinaImg,
        length: '8.5 м',
        port: 'ул. Уральская д. 13ц',
        passengers: '7',
    },
    {
        id: '5',
        title: 'Ciera Mystic',
        price: 'от 9 000,00 ₽/час',
        img: cieraImg,
        length: '8.2 м',
        port: 'Лопухинский сад',
        passengers: '8',
    },
    {
        id: '6',
        title: 'Silver',
        price: 'от 9 000,00 ₽/час',
        img: silverImg,
        length: '7,5 м',
        port: 'Набережная реки Мойки д. 86',
        passengers: '10',
    },
    {
        id: '7',
        title: 'Monterey',
        price: 'от 12 000,00 ₽/час',
        img: montereyImg,
        length: '9.5 м',
        port: 'Набережная реки Фонтанки д. 34',
        passengers: '10',
    },
    {
        id: '8',
        title: 'California',
        price: 'от 12 000,00 ₽/час',
        img: californiaImg,
        length: '9.5 м',
        port: 'Петровский проспект д.2 стр.2',
        passengers: '9',
    },
    {
        id: '9',
        title: 'Doral 300 Barbados',
        price: 'от 11 000,00 ₽/час',
        img: doralImg,
        length: '9 м',
        port: 'улица Академика Павлова д. 11а',
        passengers: '10',
    },
    {
        id: '10',
        title: 'Pioneer',
        price: 'от 13 000,00 ₽/час',
        img: pioneerImg,
        length: '7 м',
        port: 'Ждановская набережная д.2',
        passengers: '10',
    },
    {
        id: '11',
        title: 'Veneto',
        price: 'от 15 000,00 ₽/час',
        img: venetoImg,
        length: '10 м',
        port: 'Ждановская набережная д.2а',
        passengers: '10',
    },
    {
        id: '12',
        title: 'Венеция',
        price: 'от 10 000,00 ₽/час',
        img: venуziaImg,
        length: '9 м',
        port: 'Набережная реки Мойки д.86',
        passengers: '11',
    },
    {
        id: '13',
        title: 'Венеция Норд',
        price: 'от 22 000,00 ₽/час',
        img: venуzianordImg,
        length: '11 м',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '10',
    },
    {
        id: '14',
        title: 'Престиж',
        price: 'от 12 000,00 ₽/час',
        img: prestigeImg,
        length: '7 м',
        port: 'Набережная реки Мойки д.1',
        passengers: '6',
    },
    {
        id: '15',
        title: 'Катарина',
        price: 'от 15 000,00 ₽/час',
        img: katarinaImg,
        length: '10 м',
        port: 'Набережная реки Фонтанки д.64',
        passengers: '11',
    },
    {
        id: '16',
        title: 'Венисса',
        price: 'от 14 000,00 ₽/час',
        img: venissaImg,
        length: '9 м',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '11',
    },
    {
        id: '17',
        title: 'Mark',
        price: 'от 12 000,00 ₽/час',
        img: markImg,
        length: '10 м',
        port: 'ул. Акадмека Павлова д.11а',
        passengers: '11',
    },
    {
        id: '18',
        title: 'Премьера',
        price: 'от 25 000,00 ₽/час',
        img: premieraImg,
        length: '12 м',
        port: 'Набережная реки Мойки д.86',
        passengers: '11',
    },
    {
        id: '19',
        title: 'Velvette 330 Белуга',
        price: 'от 18 000,00 ₽/час',
        img: velvetteImg,
        length: '11 м',
        port: 'ул. Акадмека Павлова д.11а',
        passengers: '11',
    },
    {
        id: '20',
        title: 'Эфир 330',
        price: 'от 12 000,00 ₽/час',
        img: efirImg,
        length: '10 м',
        port: 'Петровский проспект д.3',
        passengers: '11',
    },
    {
        id: '21',
        title: 'Ялта',
        price: 'от 20 000,00 ₽/час',
        img: yaltaImg,
        length: '13 м',
        port: 'Набережная реки Мойки д.86',
        passengers: '11',
    },
    {
        id: '22',
        title: 'Beluga',
        price: 'от 20 000,00 ₽/час',
        img: belugaImg,
        length: '13 м',
        port: 'Набережная реки Мойки д.17',
        passengers: '10',
    },
    {
        id: '23',
        title: 'Джерри',
        price: 'от 10 000,00 ₽/час',
        img: jerryImg,
        length: '',
        port: '',
        passengers: '10',
    },
    {
        id: '24',
        title: 'Франческо',
        price: 'от 7 000,00 ₽/час',
        img: francescoImg,
        length: '',
        port: '',
        passengers: '6',
    },
    {
        id: '25',
        title: 'Эль Чапа',
        price: 'от 12 000,00 ₽/час',
        img: elchapaImg,
        length: '',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '11',
    },
    {
        id: '26',
        title: 'Магнум',
        price: 'от 6 000,00 ₽/час',
        img: magnumImg,
        length: '',
        port: '',
        passengers: '5',
    },
    {
        id: '27',
        title: 'Anastasiya',
        price: 'от 10 000,00 ₽/час',
        img: anastasiyaImg,
        length: '8.5 м',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '11',
    },
    {
        id: '28',
        title: 'Мэри',
        price: 'от 8 500,00 ₽/час',
        img: maryImg,
        length: '',
        port: 'Набережная реки Фонтанки д.53а',
        passengers: '7',
    },
    {
        id: '29',
        title: 'Юлия',
        price: 'от 12 000,00 ₽/час',
        img: juliImg,
        length: '',
        port: 'ул. Академика Павлова д.11а',
        passengers: '11',
    },
    {
        id: '30',
        title: 'Storm II',
        price: 'от 8 000,00 ₽/час',
        img: storm2Img,
        length: '',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '9',
    },
    {
        id: '31',
        title: 'Regal 26',
        price: 'от 8 000,00 ₽/час',
        img: regal26Img,
        length: '',
        port: 'ул. Уральская д.13ц',
        passengers: '7',
    },
    {
        id: '32',
        title: 'Кира',
        price: 'от 8 000,00 ₽/час',
        img: kiraImg,
        length: '',
        port: 'ул. Академика Павлова д.11а',
        passengers: '7',
    },
    {
        id: '33',
        title: 'Бали',
        price: 'от 18 000,00 ₽/час',
        img: baliImg,
        length: '11.5 м',
        port: 'Южная дорога д.4',
        passengers: '11',
    },
    {
        id: '34',
        title: 'Chaparral SSI Blue',
        price: 'от 9 000,00 ₽/час',
        img: chapssiImg,
        length: '7 м',
        port: 'наб. Канала Грибоедова д.62',
        passengers: '11',
    },
    {
        id: '35',
        title: 'Chaparral 260 Signature',
        price: 'от 9 000,00 ₽/час',
        img: chapsigImg,
        length: '',
        port: 'Ждановская набережная. д.2',
        passengers: '7',
    },
    {
        id: '36',
        title: 'Crowline 262',
        price: 'от 9 000,00 ₽/час',
        img: crowline262Img,
        length: '8.5 м',
        port: 'Ждановская набережная. д.2а',
        passengers: '8',
    },
    {
        id: '37',
        title: 'Four Wings 240',
        price: 'от 7 000,00 ₽/час',
        img: fourwinsImg,
        length: '',
        port: 'наб. Канала Грибоедова д.68',
        passengers: '7',
    },
    {
        id: '38',
        title: 'Butterfly',
        price: 'от 13 000,00 ₽/час',
        img: butterflyImg,
        length: '9 м',
        port: 'ул. Уральская д.13ц',
        passengers: '9',
    },
    {
        id: '39',
        title: 'Monte Carlo',
        price: 'от 8 000,00 ₽/час',
        img: montecarloImg,
        length: '7.5',
        port: 'ул. Академика Павлова д.11а',
        passengers: '7',
    },
    {
        id: '40',
        title: 'Doral 330 Expression',
        price: 'от 15 000,00 ₽/час',
        img: doral300expImg,
        length: '10 м',
        port: 'ул. Академика Павлова д.11а',
        passengers: '11',
    },
    {
        id: '41',
        title: 'Борт №1',
        price: 'от 6 000,00 ₽/час',
        img: bort1Img,
        length: '',
        port: 'Набережная реки Фонтанки д.34',
        passengers: '6',
    },
    {
        id: '42',
        title: 'Щукин сын',
        price: 'от 7 500,00 ₽/час',
        img: shykinsinImg,
        length: '',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '9',
    },
    {
        id: '43',
        title: 'Симба',
        price: 'от 6 000,00 ₽/час',
        img: simbaImg,
        length: '',
        port: 'наб. Канала Грибоедова д.63',
        passengers: '6',
    },
    {
        id: '44',
        title: 'Боливар',
        price: 'от 8 000,00 ₽/час',
        img: boliwarImg,
        length: '',
        port: 'Ждановская набережная. д.2а',
        passengers: '6',
    },
    {
        id: '45',
        title: 'Nord',
        price: 'от 8 000,00 ₽/час',
        img: nordImg,
        length: '',
        port: 'ул. Академика Павлова д.11а',
        passengers: '7',
    },
    {
        id: '46',
        title: 'Interpol',
        price: 'от 8 000,00 ₽/час',
        img: interpolImg,
        length: '',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '8',
    },
    {
        id: '47',
        title: 'Storm III',
        price: 'от 7 500,00 ₽/час',
        img: storm3Img,
        length: '',
        port: 'Набережная реки Фонтанки д.53а',
        passengers: '6',
    },
    {
        id: '48',
        title: 'Сириус',
        price: 'от 11 000,00 ₽/час',
        img: siriusImg,
        length: '',
        port: 'Набережная реки Фонтанки д.53',
        passengers: '11',
    },
    {
        id: '49',
        title: 'Corsa',
        price: 'от 7 000,00 ₽/час',
        img: corsaImg,
        length: '',
        port: '',
        passengers: '6',
    },
    {
        id: '50',
        title: 'Dea',
        price: 'от 7 500,00 ₽/час',
        img: deaImg,
        length: '',
        port: 'Набережная реки Фонтанки д.115',
        passengers: '4',
    },
    {
        id: '51',
        title: 'Wizard',
        price: 'от 7 000,00 ₽/час',
        img: wizardImg,
        length: '',
        port: 'Ждановская набережная. д.2',
        passengers: '5',
    },
    {
        id: '52',
        title: 'Sea Ray 270 SD Gray',
        price: 'от 10 000,00 ₽/час',
        img: SeaRay270SDGrayImg,
        length: '',
        port: 'Южная дорога д.4 к.1, рядом с рестораном Сандей',
        passengers: '11',
    },
    {
        id: '53',
        title: 'Sea Ray 270 SLX Black',
        price: 'от 10 000,00 ₽/час',
        img: SeaRay270SLXBlackImg,
        length: '',
        port: '',
        passengers: 'Южная дорога д.4 к.1, рядом с рестораном Сандей',
    },
    {
        id: '54',
        title: 'Sea Doo Challenger',
        price: 'от 6 000,00 ₽/час',
        img: brpImg,
        length: '',
        port: '',
        passengers: '',
    },
    {
        id: '55',
        title: 'Грин',
        price: 'от 9 000,00 ₽/час',
        img: greenImg,
        length: '',
        port: 'Набережная реки Фонтанки д.21',
        passengers: '10',
    },
    {
        id: '56',
        title: 'Гарибальди',
        price: 'от 7 500,00 ₽/час',
        img: garibaldiImg,
        length: '',
        port: '',
        passengers: '9',
    },
    {
        id: '57',
        title: 'Chaprral 204 Кейптаун',
        price: 'от 9 000,00 ₽/час',
        img: chap204Img,
        length: '7 м',
        port: 'Набережная реки Мойки д.11',
        passengers: '8',
    },
    {
        id: '58',
        title: 'Инфинити',
        price: 'от 8 500,00 ₽/час',
        img: infinityImg,
        length: '',
        port: '',
        passengers: '6',
    },
    {
        id: '59',
        title: 'Нэшвилл',
        price: 'от 10 000,00 ₽/час',
        img: nashvilleImg,
        length: '',
        port: '',
        passengers: '8',
    },
    {
        id: '60',
        title: 'Chaparral 290 Paradise',
        price: 'от 12 000,00 ₽/час',
        img: paradiseImg,
        length: '',
        port: '',
        passengers: '10',
    },
    {
        id: '61',
        title: 'Лакки',
        price: 'от 7 000,00 ₽/час',
        img: lakkiImg,
        length: '',
        port: '',
        passengers: '5',
    },
    {
        id: '62',
        title: 'Теодор',
        price: 'от 10 000,00 ₽/час',
        img: teodorImg,
        length: '',
        port: '',
        passengers: '',
    },
    


];

export { katerInfo }