import React, { useEffect } from 'react';
import { NavLink } from "react-router-dom";
import "./style.css";

const FooterOne = () => {

    useEffect(() => {
        const showHiddenDiv = (event) => {
            event.preventDefault();
            var hiddenDiv = document.getElementById('hidden_div');
            if (hiddenDiv) {
                hiddenDiv.style.display = 'block';
                var moreLink = document.querySelector('.more a');
                if (moreLink) {
                    moreLink.parentNode.removeChild(moreLink);
                }
            }
            return false;
        };

        const addEventListeners = () => {
            var moreLink = document.querySelector('.more a');
            if (moreLink) {
                moreLink.addEventListener('click', showHiddenDiv);
            }
        };

        addEventListeners();

        return () => {
            var moreLink = document.querySelector('.more a');
            if (moreLink) {
                moreLink.removeEventListener('click', showHiddenDiv);
            }
        };
    }, []);

    return (
        <>
            <div className="line"></div>
            <div className="left">
                <p className="left-text">ROYAL MARINE: yacht rental</p>
                <p className="left-text-bot">Приветствуем вас на сайте компании <b>ROYAL MARINE</b>.</p>
                <p className="left-text-bot">Мы предоставляем в аренду самые красивые, быстрые и комфортабельные яхты и катера в Санкт-Петербурге!</p>
                <p className="left-text-bot">Также к вашим услугам: кейтеринг, гид/экскурсовод на борту, диджей, фотограф.</p>
                <p className="more"><NavLink>Читать
                    далее...</NavLink></p>
                <div id="hidden_div" className="hidden_div">
                    <p>Все суда в нашем флоте лицензированы,полностью исправны, чисты и оборудованы всем необходимым
                        для вашего отдыха: от бокалов, посуды и теплых пледов до мощной аудио- и видеоаппаратуры.</p>
                    <p>Все члены экипажа в нашем флоте - опытные мастера своего дела.</p>
                    <p>Наша задача - сделать ваш отдых безопасным и <b>незабываемым</b>!</p>
                    <p>Позвоните или напишите нам и мы подготовим любое судно к вашему мероприятию, будь то романтическая
                        прогулка, семейное торжество, посиделки с друзьями или деловая встреча!</p>
                    <p>Успейте забронировать! Работаем 24/7 #номер телефона <NavLink to="tel:+79213455678"><b>+7 921 235 34 45</b></NavLink></p>
                </div>
            </div>
            <div className="right">
                <p className="right-text">Работаем 24/7</p>
                <p className="right-text">Простое и быстрое оформление</p>
                <p className="right-text">Любые способы оплаты</p>
                <p className="right-text">Сотрудничаем с Физическими и Юридическими лицами</p>
                <p className="right-text">Предоставляем полный пакет документов</p>
            </div>
        </>
    );
}

export default FooterOne;