import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import './style.css';
import { SwiperMain } from "./../components/swiper/SwiperMain";
import ShowOtherYacht from "../components/show-other/ShowOtherYacht";
import ShowOtherKater from "../components/show-other/ShowOtherKater";
import ShowOtherTeplohod from "../components/show-other/ShowOtherTeplohod";
import { yachtsInfo } from "../helpers/yachtsInfo";
import { katerInfo } from '../helpers/katerInfo';
import { teplohodInfo } from '../helpers/teplohodInfo';


export const HomeSwiper = () => {
    return (<><SwiperMain />
        <div className='wrapper-sw'>
            <div className="featured">
                <p>Яхты</p>
            </div>
            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                speed={800}
                lazyPreloadPrevNext={0}
                breakpoints={{
                    280: {
                        slidesPerView: 1.1,
                        spaceBetween: 0,
                    },
                    360: {
                        slidesPerView: 1.5,
                        spaceBetween: 0,
                    },
                    390: {
                        slidesPerView: 1.6,
                        spaceBetween: 0,
                    },
                    414: {
                        slidesPerView: 1.7,
                        spaceBetween: 0,
                    },
                    540: {
                        slidesPerView: 2.2,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 3.1,
                        spaceBetween: 0,
                    },
                    820: {
                        slidesPerView: 3.2,
                        spaceBetween: 0,
                    },
                    912: {
                        slidesPerView: 3.7,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 4.1,
                        spaceBetween: 0,
                    },
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}

            >
                {yachtsInfo.map((yachtsInfo) => {
                    return <SwiperSlide key={yachtsInfo.id}>
                        <ShowOtherYacht title={yachtsInfo.title} img={yachtsInfo.img} price={yachtsInfo.price} key={yachtsInfo.id} id={yachtsInfo.id} />
                    </SwiperSlide>
                })}
            </Swiper>
            <div className="featured">
                <p>Катера</p>
            </div>
            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                speed={800}
                lazyPreloadPrevNext={0}
                breakpoints={{
                    280: {
                        slidesPerView: 1.1,
                        spaceBetween: 0,
                    },
                    360: {
                        slidesPerView: 1.5,
                        spaceBetween: 0,
                    },
                    390: {
                        slidesPerView: 1.6,
                        spaceBetween: 0,
                    },
                    414: {
                        slidesPerView: 1.7,
                        spaceBetween: 0,
                    },
                    540: {
                        slidesPerView: 2.2,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 3.1,
                        spaceBetween: 0,
                    },
                    820: {
                        slidesPerView: 3.2,
                        spaceBetween: 0,
                    },
                    912: {
                        slidesPerView: 3.7,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 4.1,
                        spaceBetween: 0,
                    },
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}

            >
                {katerInfo.map((katerInfo) => {
                    return <SwiperSlide key={katerInfo.id}>
                        <ShowOtherKater title={katerInfo.title} img={katerInfo.img} price={katerInfo.price} key={katerInfo.id} id={katerInfo.id} />
                    </SwiperSlide>
                })}
            </Swiper>
            <div className="featured">
                <p>Теплоходы</p>
            </div>
            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                lazyPreloadPrevNext={0}
                speed={800}
                breakpoints={{
                    280: {
                        slidesPerView: 1.1,
                        spaceBetween: 0,
                    },
                    360: {
                        slidesPerView: 1.5,
                        spaceBetween: 0,
                    },
                    390: {
                        slidesPerView: 1.6,
                        spaceBetween: 0,
                    },
                    414: {
                        slidesPerView: 1.7,
                        spaceBetween: 0,
                    },
                    540: {
                        slidesPerView: 2.2,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 3.1,
                        spaceBetween: 0,
                    },
                    820: {
                        slidesPerView: 3.2,
                        spaceBetween: 0,
                    },
                    912: {
                        slidesPerView: 3.7,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 4.1,
                        spaceBetween: 0,
                    },
                }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}

            >
                {teplohodInfo.map((teplohodInfo) => {
                    return <SwiperSlide key={teplohodInfo.id}>
                        <ShowOtherTeplohod title={teplohodInfo.title} img={teplohodInfo.img} price={teplohodInfo.price} key={teplohodInfo.id} id={teplohodInfo.id} />
                    </SwiperSlide>
                })}
            </Swiper>
        </div></>
    );
};