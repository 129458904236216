import Burger from "../burger-menu/Burger";
import "./style.css"
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

function Header() {
  useEffect(() => {
    // Проверяем, содержит ли URL адрес якорь "#footer"
    if (window.location.href.includes("#footer")) {
      // Если да, прокручиваем страницу к футеру
      const footer = document.getElementById("footer");
      if (footer) {
        footer.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (<div className="header">
    <div className="rest">
      <div className="nav-row">
        <div className="nav-left">
          <NavLink to="/"><div className="logo"><strong>ROYAL MARINE</strong></div></NavLink>
        </div>
        <div className="nav-right">
          <div className="tel"><NavLink to="tel:+79112989904">+7 911 298 99 04</NavLink></div>
          <ul className="menu">
            <li className="menu__item"><NavLink to="/yachts">ЯХТЫ</NavLink></li>
            <li className="menu__item"><NavLink to="/katers">КАТЕРА</NavLink></li>
            <li className="menu__item"><NavLink to="/teplohods">ТЕПЛОХОДЫ</NavLink></li>
            <li className="menu__item"><NavLink to="/catering">КЕЙТЕРИНГ</NavLink></li>
            <li className="menu__item"><a href="#footer">О НАС</a></li>
          </ul>
        </div>
        <Burger />
      </div>
    </div>
  </div>)
}

export default Header;