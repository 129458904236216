function importAllKater(r) {
    return r.keys().map(r);
}


export function getSlideContextKater(katerInfo) {
let slideContextKater;

if (katerInfo && katerInfo.id === '0') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Montferrand", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '1') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Chaparral25", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '2') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Nordik38", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '3') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Katerina", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '4') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Katalina", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '5') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/CieraMystic", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '6') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Silver", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '7') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Monterey", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '8') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/California", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '9') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Doral300Barbados", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '10') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Pioneer", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '11') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Veneto", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '12') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Venezia", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '13') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/VeneziaNord", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '14') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Prestige", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '15') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Katarina", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '16') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Venissa", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '17') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Mark", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '18') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Premiera", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '19') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Velvette330Beluga", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '20') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Efir330", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '21') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Yalta", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '22') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Beluga", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '23') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Jerry", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '24') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Francesco", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '25') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/ElChapa", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '26') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Magnum", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '27') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Anastasiya", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '28') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Mary", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '29') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Juliya", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '30') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Storm2", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '31') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Regal26", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '32') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Kira", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '33') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Bali", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '34') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/ChaparralSSI", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '35') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/ChaparralSignature", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '36') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Crowline262", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '37') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Fourwinns", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '38') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Butterfly", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '39') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/MonteCarlo", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '40') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Doral330Expression", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '41') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Bort1", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '42') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/ShykinSin", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '43') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Simba", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '44') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Boliwar", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '45') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Nord", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '46') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Interpol", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '47') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Storm3", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '48') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Sirius", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '49') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Corsa", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '50') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Dea", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '51') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Wizard", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '52') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/SeaRay270SDGray", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '53') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/SeaRay270SLXBlack", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '54') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/BRPChallenger", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '55') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Green", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '56') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Garibaldi", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '57') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Chaparral204", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '58') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Infinity", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '59') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Nashville", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '60') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Paradise", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '61') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Lakki", false, /\.(jpg)$/));
} else if (katerInfo && katerInfo.id === '62') {
    slideContextKater = importAllKater(require.context("./../media/img/kater-img/Teodor", false, /\.(jpg)$/));
} else {
    slideContextKater = [];
}

return slideContextKater;
}