import "./style.css"
import { NavLink } from "react-router-dom";

const BoatsMedium = ({title, price, id, img}) => {
    return (<div className="page-yachts-catalog">
        <NavLink to={`/cardteplohod/${id}`} target="_blank">
            <div className="page-yacht-picture" ><img src={img} alt={title}/></div>
        </NavLink>
        <div className="catalogyachtname">
            {title}
        </div>
        <div className="catalogyachtprice">
            <b>{price}</b>
        </div>
    </div>);
}

export default BoatsMedium;