import "./style.css"

const СateringAdv = () => {
    return (<div className="catering__wrapper">
        <div className="catering">
            <p>Компания <span className="ro">ROYAL MARINE</span> предоставляет широкий спектр услуг по кейтерингу для любого события.</p>
            <p>Мы сопровождаем  мероприятия любого масштаба в Санкт-Петербурге и области.</p>
            <p>Наше выездное ресторанное обслуживание отличается индивидуальным подходом к пожеланиям каждого заказчика.</p>
            <p>Мы гарантируем что будет вкусно , красиво и чётко в срок!</p>
            <ul className="adv__list">
                <li>Доставка бесплатно от 10 000₽</li>
                <li>Заказ принимается за 8 часов</li>
                <li><a className="ro1" href="tel:+79112989904">+ 7 911 298 99 04</a></li>
            </ul>
            
        </div>
    </div>);
}

export default СateringAdv;