import { NavLink } from "react-router-dom";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import "./style.css"

const FooterTwo = () => {
    return (
        <div className="footer-left" id="footer">
            <div className="map">
            <YMaps query={{apikey: 'c284e1d9-66f8-4687-82d4-1f4b8b25780e'}}>
                    <Map
                        defaultState={{ center: [59.963138, 30.247048], zoom: 9 }}
                        width="100%"
                        height="100%"
                    >
                        <Placemark geometry={[59.963138, 30.247048]} />
                    </Map>
                </YMaps>
            </div>
            <div className="footer-wrapper">
                <ul className="disc">
                    <li className="disc__item"><b>г. Санкт-Петербург</b></li>
                    <li className="disc__item"><b>Петровская коса д.9 «Речной Яхт-Клуб»</b></li>
                    <li className="disc__item"><b><NavLink to="tel:+79112989904">+7 911 298 99 04</NavLink></b></li>
                </ul>
                <div className="line-2"></div>
                <div className="messengers">
                    <NavLink to="https://wa.me/79112989904" target="_blank"><i className="fi fi-brands-whatsapp"></i></NavLink>
                    <NavLink to="https://t.me/+79112989904" target="_blank"><i className="fi fi-brands-telegram"></i></NavLink>
                    {/* <i className="fi fi-brands-vk"></i>
                    <i className="fi fi-brands-instagram"></i> */}
                </div>
                <div className="sign"><b>ROYAL MARINE 2024</b> / <span className="nae">created by  <NavLink to="https://github.com/nae111" target="blank">nae111</NavLink>
                </span></div>
            </div>
        </div>
    );
}

export default FooterTwo;